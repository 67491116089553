@use '../includes' as *;

.show_row {
  position: relative;
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  [class*='splide__track'] {
    // allows for expanded poster to be visible on hover
    overflow: visible;
  }

  @media (hover: hover) {
    &:has(a[class*='show_poster__link']:hover) {
      a[class*='show_poster__link']:not(:hover) {
        background-color: $midnight;

        img {
          opacity: 0.5;
        }
      }
    }
  }
}

.splide__slide {
  @include responsive-splide-slides;
}

// top ten show row styles

.top_ten_item__count {
  position: absolute;
  inset-block-end: -3.5%;
  inset-inline-start: 8%;
  z-index: 1;
  transition-property:
    color,
    -webkit-text-stroke-color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in-out;
  color: $yellow;
  font-size: 15vw;
  font-size: clamp(4.8rem, 15vw + 0.01em, 12rem);
  font-weight: $bold;
  line-height: 1;

  @include breakpoint($xs) {
    font-size: clamp(4.8rem, 11.5vw + 0.01em, 12rem);
  }

  @include breakpoint($sm) {
    font-size: clamp(4.8rem, 8.5vw + 0.01em, 12rem);
  }

  @include breakpoint($md) {
    color: transparent;
    -webkit-text-stroke: 3px $pebble;
  }
}

.top_ten_item {
  position: relative;
  height: 100%;
  padding-inline-start: 25%;

  &:has(a:hover, a:focus) .top_ten_item__count {
    color: $yellow;
    -webkit-text-stroke-color: $yellow;
  }
}

// the top 10 show row needs some special offsets
.top_ten_show_row {
  padding-inline-end: calc(var(--spacing-inline) * 5);

  @include breakpoint($xs) {
    padding-inline-end: calc(var(--spacing-inline) * 4);
  }

  @include breakpoint($sm) {
    padding-inline-end: calc(var(--spacing-inline) * 3);
  }

  button[class*='splide__arrow--next'] {
    translate: calc((var(--spacing-inline) * 2) + 30px) 0;

    @include breakpoint($xs) {
      translate: calc(var(--spacing-inline) + 30px) 0;
    }

    @include breakpoint($sm) {
      translate: calc((var(--spacing-inline) * 1.5) + 30px) 0;
    }
  }

  // this pushes the "1" over so it's under the poster
  .splide__slide[id*='slide01'] {
    .top_ten_item__count {
      inset-inline-start: 13%;
    }
  }

  // this scoots the 10th poster over a bit
  // so that we can see "10" behind it
  .splide__slide[id*='slide10'] {
    a {
      translate: 21% 0;
    }
  }
}
