@use '../includes' as *;
@use '@/components/ShowRow/ShowRowSplide.module.scss' as splide;

.photo_gallery__container {
  width: calc(100% + var(--spacing-inline));
  padding-inline-end: var(--spacing-inline);
  overflow: hidden;
}

.photo_gallery {
  max-width: calc(#{$xl} - (2 * var(--spacing-inline)));

  [class*='splide__track'] {
    // allows for the next and prev photo to be visible
    overflow: visible;
  }

  [class*='splide__slide'] {
    // applies border radius to photo
    overflow: clip;
    border-radius: $radius-ml;

    // gives the illusion that all the figcaptions
    // are the same height
    @include breakpoint($sm) {
      background-color: $charcoal;
    }
  }
}

.photo_gallery__photos {
  max-height: calc(100vh - 150px);
}

.photo_gallery__figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.photo_gallery__figcaption_container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  padding-block: $g8;

  @include breakpoint($sm) {
    grid-template-columns: 1fr auto 1fr;
    column-gap: 5%;
    padding-block: $g12;
    padding-inline: $g16;
  }

  @include breakpoint($md) {
    padding-block: $g16;
    padding-inline: $g20;
  }

  @include breakpoint($xl) {
    padding-block: $g20;
    padding-inline: $g24;
  }
}

.photo_gallery__caption_text {
  font-size: 1.2rem;
  font-weight: $normal;
  line-height: 1.5;

  @include breakpoint($md) {
    @include body-copy-2;
  }
}

.photo_gallery__figcaption_pagination {
  display: none;

  @include breakpoint($sm) {
    display: block;
    font-size: 1.2rem;
  }
}

.photo_gallery__figcaption_credit_text {
  margin-block-start: $g4;
  color: $pebble;
  font-size: 1.2rem;

  @include breakpoint($sm) {
    justify-self: end;
    color: $fog;
  }
}

// Need to bump the specificity to apply
// the style else the original splide styles
// will override it in Amplify & Edcar environments
button.photo_gallery__splide_arrow {
  @include splide-arrow;
}
