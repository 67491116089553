@use '../includes' as *;
// @use '../DetailHero/DetailHero.module.scss' as detailHero;

$bp: $sm;

.station_hero {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);
  background-color: $midnight;

  @include breakpoint($bp) {
    aspect-ratio: 3/1;
    margin-block-end: calc((var(--spacing-block) + 3vw) * -1);
    padding: unset;
  }
}

.station_hero_background_image {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: $radius-large $radius-large 0 0;
  background-color: $midnight;

  @include breakpoint($bp) {
    border-radius: 0;

    img {
      width: 100%;
    }
  }
}

.station_hero_back_button {
  position: absolute;
  inset: 5% var(--spacing-inline) auto auto;
  z-index: 1;
}
