@use '../includes' as *;

.video_thumbnail_carousel {
  container-type: inline-size;

  [class*='splide__track'] {
    // allows for expanded thumbnail to be visible on hover
    overflow: visible;
    // This prevents the carousel thumbnails from "spilling over" the left side when the row is scrolled
    // top: -8px allows the thumbnail hover effects to grow
    // right: -(spacing-inline) allows the content to flow past the container on the right side
    // bottom: 0 nothing to do here
    // left: -(show-poster-video-row-gap) clips the carousel at the edge of the show poster
    clip-path: inset(
      -8px calc(var(--spacing-inline) * -1) 0
        calc(var(--show-poster-video-row-gap) * -1)
    );
  }

  @media (hover: hover) {
    &:has(a[class*='video_mezzanine_link']:hover) {
      a[class*='video_mezzanine_link']:not(:hover) {
        opacity: 0.5;
        background-color: $midnight;
      }
    }
  }
}

button.splide__arrow {
  @include splide-arrow;

  & {
    inset-block-start: 21cqw;
    margin-block-start: -30px;
  }

  @include breakpoint($xs) {
    inset-block-start: 18cqw;
  }

  @include breakpoint($sm) {
    inset-block-start: 10.5cqw;
  }

  @include breakpoint($md) {
    inset-block-start: 10cqw;
  }

  @include breakpoint($xl) {
    inset-block-start: 7.5cqw;
  }
}
