@use '../includes' as *;

.photo_gallery__photo {
  max-width: 100%;
  // @TODO is there a better way to handle the height problem than these magic numbers?
  max-height: calc(100vh - 390px);
  object-fit: contain;
  border-radius: 0;

  @include breakpoint($xl) {
    max-height: calc(100vh - 420px);
  }
}

.photo_gallery__photo_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $shadow;
}
