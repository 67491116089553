@use '../includes' as *;

.splide__list {
  .splide__slide {
    &:nth-child(1) {
      div {
        span {
          translate: 13px;
        }
      }
    }

    &:nth-child(10) {
      div {
        a {
          inset-inline-start: 45px;
        }
      }
    }
  }
}

// Need to bump the specificity to apply
// the style else the original splide styles
// will override it in Amplify & Edcar environments
button.splide__arrow {
  @include splide-arrow;
}
