@use '../includes' as *;

$bp: $sm;

.station_hero_overlay {
  display: flex;
  container-type: inline-size;
  flex-direction: column;
  justify-content: center;
  gap: $g12;
  padding-block: 20px;
  padding-inline: var(--spacing-inline);
  border-radius: 0 0 $radius-large $radius-large;
  background-color: $navy-blue;
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }

  @include breakpoint($bp) {
    position: absolute;
    inset: 0;
    gap: $g16;
    margin-block: 0;
    border-radius: unset;
    background-color: transparent;
    background-image:
      linear-gradient(
        to top,
        $midnight 5%,
        rgba($midnight, 0.36) 22%,
        rgba($midnight, 0) 38%
      ),
      linear-gradient(
        to right,
        rgba($midnight, 0.9) 5%,
        rgba($midnight, 0.36) 50%,
        rgba($midnight, 0) 70%
      );
  }

  @include breakpoint($xl) {
    gap: $g20;
  }
}

.station_hero_overlay_station_logo {
  img {
    width: auto;
    max-width: clamp(100px, 10.87vw + 6.522rem, 250px);
    height: auto;
    min-height: 20px;
    max-height: 50px;
  }
}

.station_hero_overlay_description {
  @include body-copy-3;

  @include breakpoint($bp) {
    @include body-copy-1;

    max-width: 35%;
    font-size: clamp(1.4rem, 1.1714rem + 0.2976vw, 1.6rem);
  }
}
